import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import Testimonials from '../components/Testimonials';
import PageLayout from '../layouts/Page';
import illu123Fleurs from '../images/blog/frederic_guffroy.png';
import screenshotAffiliateMarketing from '../images/screenshot-affiliate-marketing.png';
import screenshotAnalytics from '../images/product-screenshot.png';
import screenshotBrandSafety from '../images/brand-safety-screenshot.png';
import TrustUs from '../components/TrustUs';
import illu from '../images/homeillu.svg';
import ThankYouSmallLogo from '../images/small_logo.svg';

export default function Homepage() {
  return (
    <PageLayout>
      <Helmet>
        <title>Réinventez le pilotage de l'acquisition avec Thank-You Analytics</title>
        <meta
          name="description"
          content="Nous accompagnons les équipes marketing dans la compréhension des performances des campagnes d'acquisition grâce à des solutions qui simplifient leur quotidien."
        />
      </Helmet>
      <div className="lg:relative">
        <div className="mx-auto max-w-7xl w-full sm:pt-16 sm:pb-20 pt-8 pb-10 text-center lg:py-32 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8">
            <h1 className="font-title text-3xl md:text-5xl tracking-tight font-extrabold text-gray-900">
              <span className="block xl:inline">
                Réinventez le pilotage de l'acquisition avec{' '}
                <span className="block text-green-brand">Thank-You Analytics</span>
              </span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Nous accompagnons les équipes marketing dans la compréhension des performances des
              campagnes d'acquisition grâce à des solutions qui simplifient leur quotidien.
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <Link
                  to="/contact"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-brand hover:bg-green-brandHover md:py-4 md:text-lg md:px-10"
                >
                  Demande de démo
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img className="absolute inset-0 w-full h-full" src={illu} alt="" />
        </div>
      </div>
      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <h2 className="font-title text-3xl font-extrabold tracking-tight text-gray-900">
                  <div className="relative flex flex-column items-center">
                    <img
                      className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                      src={ThankYouSmallLogo}
                      alt="Thank-You Analytics Logo"
                    />
                    <span>Marketing</span>
                  </div>
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Analysez la performance de vos campagnes en respectant l'ePrivacy + RGPD et la vie
                  privée des internautes : développé en réponse aux nouvelles obligations de
                  l'ePrivacy au 1er avril 2021, TY Marketing propose une nouvelle approche
                  permettant d'analyser la performance des campagnes d'acquisition tout en
                  respectant la vie privée de votre audience.
                </p>
                <div className="mt-6">
                  <Link
                    to="/marketing-analytics"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-brand hover:bg-green-brandHover"
                  >
                    En savoir plus
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={screenshotAnalytics}
                  alt="Thank-You Marketing"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative mt-16">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-24 lg:max-w-none lg:mx-0 lg:px-0">
              <div className="">
                <h2 className="font-title text-3xl font-extrabold tracking-tight text-gray-900">
                  <div className="relative flex flex-column items-center">
                    <img
                      className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                      src={ThankYouSmallLogo}
                      alt="Thank-You Analytics Logo"
                    />
                    <span>Affiliate</span>
                  </div>
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Boostez votre programme d'affiliation : solution complète de pilotage de
                  l'affiliation directement synchronisée avec vos plateformes.
                </p>
                <div className="mt-6">
                  <Link
                    to="/affiliate-marketing/"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-brand hover:bg-green-brandHover"
                  >
                    En savoir plus
                  </Link>
                </div>
              </div>

              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;Grâce à Thank-You nous avons nettoyé notre base d'affiliés afin de
                      repartir sur des bases saines. Nous avons ainsi divisé notre budget
                      affiliation par 10 sans avoir perdu une seule vente !&rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-6 w-6 rounded-full"
                          src={illu123Fleurs}
                          alt="Thank-You Affiliate"
                        />
                      </div>
                      <div className="text-base font-medium text-gray-700">
                        Frédéric Guffroy, Co-fondateur d"123Fleurs
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={screenshotAffiliateMarketing}
                  alt="Inbox user interface"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-40 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <h2 className="font-title text-3xl font-extrabold tracking-tight text-gray-900">
                  <div className="relative flex flex-column items-center">
                    <img
                      className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                      src={ThankYouSmallLogo}
                      alt="Thank-You Analytics Logo"
                    />
                    <span>Brand Safety</span>
                  </div>
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Protégez l'achat de votre marque en SEA : l'achat de marque sur les moteurs de
                  recherche est une pratique commune. Cependant, bien qu'autorisé, il est nécessaire
                  d'être vigilant sur les sites qui achètent votre marque mais aussi sur le contenu
                  des annonces.
                </p>
                <div className="mt-6">
                  <Link
                    to="/brand-safety"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-brand hover:bg-green-brandHover"
                  >
                    En savoir plus
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={screenshotBrandSafety}
                  alt="Thank-You Marketing"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TrustUs />
      <Testimonials />
    </PageLayout>
  );
}
