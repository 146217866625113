import React from 'react';

import illu123Fleurs from '../images/clients/123fleurs.png';
import illuBelambra from '../images/clients/belambra.png';
import illuDamart from '../images/clients/damart.png';
import illuSport2000 from '../images/clients/sport2000.png';
import illuWda from '../images/clients/wda.png';
import illuWoodbrass from '../images/clients/woodbrass.png';
import illuQuitoque from '../images/clients/quitoque.png';
import illuAdvise from '../images/clients/advise.png';
import illuPrintemps from '../images/clients/printemps_com.png';

const TrustUs = () => {
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="font-title text-center text-base font-semibold uppercase text-gray-700 tracking-wider">
          Ils nous font confiance
        </p>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
            <img
              className="max-h-12 max-w-full object-contain"
              src={illuDamart}
              alt="Damart"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
            <img
              className="max-h-12 max-w-full object-contain"
              src={illuPrintemps}
              alt="Printemps.com"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
            <img
              className="max-h-12 max-w-full object-contain"
              src={illuAdvise}
              alt="Advise"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
            <img
              className="max-h-12 max-w-full object-contain"
              src={illuQuitoque}
              alt="Quitoque"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
            <img
              className="max-h-12 max-w-full object-contain"
              src={illu123Fleurs}
              alt="123Fleurs"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
            <img
              className="max-h-12 max-w-full object-contain"
              src={illuBelambra}
              alt="Belambra"
            />
          </div>

          <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
            <img
              className="max-h-12 max-w-full object-contain"
              src={illuSport2000}
              alt="Sport2000"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
            <img
              className="max-h-12 max-w-full object-contain"
              src={illuWda}
              alt="Web Deal Auto"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-white">
            <img
              className="max-h-12 max-w-full object-contain"
              src={illuWoodbrass}
              alt="Woodbrass"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustUs;
